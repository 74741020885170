<template>
  <div>
    <t-form ref="form" @submit.prevent>
      <ContactInformationFormPart @result="onResult" @change="onChange" />

      <LeadSubmitFormPart
        ref="leadSubmit"
        :is-optional="true"
        hidden
        @result="onResult"
        @change="onChange"
        @leadPostValid="onLeadPostValid"
        @leadPostInvalid="onLeadPostInvalid"
        @leadPostError="onLeadPostError"
      />
    </t-form>

    <FormErrorMessages
      v-if="errorMessages.length"
      :error-messages="customErrorMessage"
    />
  </div>
</template>

<script>
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'
import ErrorPage from 'chimera/all/themes/blueflow/page/ErrorPage'
import ContactInformationFormPart from 'chimera/all/themes/blueflow/components/form/part/contactInformation/ContactInformationFormPart'
import LeadSubmitFormPart from 'chimera/all/components/form/parts/LeadSubmitFormPart.vue'
import ThankYouPage from '~/pages/offertes-aanvragen/aanvraag-afgerond'

export default {
  name: 'ContactInformationFormStep',

  components: {
    ContactInformationFormPart,
    LeadSubmitFormPart,
    FormErrorMessages,
  },

  extends: AbstractFormStep,

  /**
   * @returns {object}
   */
  data() {
    return {
      willTransitionOnValid: true,
      customErrorMessage: ['Controleer je gegevens'],
    }
  },

  /**
   * Pass through the submit event given from FormModal
   */
  created() {
    this.$nuxt.$on('submit', this.onSubmit)
  },

  /**
   * Clean up before component is destroyed
   */
  destroyed() {
    this.$nuxt.$off('submit')
  },

  methods: {
    /**
     * Perform lead post.
     * Based on inner logic of `postLead` the callback will be in `onLeadPost*` methods.
     */
    async transition() {
      await this.$refs.leadSubmit.postLead()
      // PD-7092
      await this.$absmartly.refresh()
    },

    /**
     * On successful lead post
     */
    onLeadPostValid() {
      this.routeTo(ThankYouPage)
    },

    /**
     * On invalid lead post
     */
    onLeadPostInvalid() {
      this.routeTo(ErrorPage)
    },

    /**
     * Lead post resulted in an error
     */
    onLeadPostError() {
      this.routeTo(ErrorPage)
    },
  },
}
</script>
